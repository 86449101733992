import queryToString from "helpers/queryToString";
import { baasApiCall } from "services/apiCall";

export const queryGetDashboardData = (values) => {
  const searchParams = {
    timeInterval: values.timeInterval,
    periodMinutes: values.periodMinutes,
  };
  return baasApiCall(
    "GET",
    `/pm/metric/pix/merchant/limit-validator/dash/${
      values.clientId
    }${queryToString(searchParams)}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetPixImpactsByTaxId = (values) => {
  const { clientId, taxId, params } = values;
  return baasApiCall(
    "GET",
    `/pm/metric/pix/merchant/limit-validator/person/${clientId}/taxId/${taxId}${queryToString(
      params
    )}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetPixPaymentInOut = (values) => {
  return baasApiCall(
    "GET",
    `/pm/metric/pix/merchant/payment/end-to-end/in-out/${values}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetPixQrCode = (values) => {
  return baasApiCall("GET", `/pm/metric/pix/merchant/qrcode/${values}`)
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetReportConfigs = () => {
  return baasApiCall("GET", `/pm/pix/merchant/limit-validator-v2/report-config`)
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationUpdateReportConfigs = (payload) => {
  return baasApiCall(
    "PATCH",
    `/pm/pix/merchant/limit-validator-v2/report-config/${payload.id}`,
    payload
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationDeleteReportConfigs = (pactualId) => {
  return baasApiCall(
    "DELETE",
    `/pm/pix/merchant/limit-validator-v2/report-config/${pactualId}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationCreateReportConfig = (payload) => {
  return baasApiCall(
    "POST",
    `/pm/pix/merchant/limit-validator-v2/report-config`,
    payload
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetProprietaryTefAccounts = () => {
  return baasApiCall("GET", `/in/tefProprietary`)
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationProprietaryTefCreateTransaction = (payload, otp) => {
  return baasApiCall(
    "POST",
    `/in/tefProprietary/create-transaction`,
    payload,
    otp
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationProprietaryExternalTransaction = (payload, otp) => {
  return baasApiCall(
    "POST",
    `/in/pix/merchant/limit-validator-v2/proprietary-account/${payload.accountIdFrom}`,
    payload,
    otp
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetAuthorizedInstitutionsFile = (params) => {
  return baasApiCall(
    "GET",
    `/pm/pix/merchant/limit-validator-v2/authorized-institutions/file${queryToString(
      params
    )}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetScoreFile = (params) => {
  return baasApiCall(
    "GET",
    `/pm/pix/merchant/limit-validator-v2/score/file${queryToString(params)}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetInfractionFile = (params) => {
  return baasApiCall(
    "GET",
    `/pm/pix/merchant/limit-validator-v2/infraction-notification/report${queryToString(
      params
    )}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetStatement = (params) => {
  return baasApiCall("GET", `/in/settle-movement${queryToString(params)}`)
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetStatementPagination = (params) => {
  return baasApiCall("POST", `/in/settle-movement/toScroll`, params)
    .then((data) => data.json())
    .catch((error) => error);
};
export const queryPixMerchantByTaxId = async (params) => {
  return baasApiCall(
    "GET",
    `/pm/pix/merchant/limit-validator-v2/customer/tax-id/${params.taxId}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetPixMerchant = async () => {
  return baasApiCall("GET", `/pm/pix/merchant/limit-validator-v2/customer`)
    .then((data) => data.json())
    .catch((error) => error);
};
