import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { CircularProgress } from "@mui/material";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import { useTheme } from "@mui/system";

export default function TransactionalPanel({ state, loading, approveTr }) {
    const theme = useTheme();
    return (
        <>
            {state.error ? (
                <>
                    <BaasGrid
                        p="16px"
                        sx={{ background: theme.palette.grey[100] }}
                    >
                        <div style={{ textAlign: "center", padding: "16px 0" }}>
                            <DoDisturbIcon
                                sx={{ color: "#D22D4B" }}
                                fontSize="large"
                            />
                            <BaasText
                                textAlign="center"
                                variant="h5"
                                color="error.main"
                            >
                                {state.errorMessage}
                            </BaasText>
                        </div>
                    </BaasGrid>
                </>
            ) : null}

            {/* Client Logo Display */}
            {state.trData !== null ? (
                <>
                    <BaasRow item spacing={1}>
                        <BaasText variant="h6">PISP Organization Logo</BaasText>
                    </BaasRow>
                    <BaasGrid
                        p="16px"
                        sx={{ background: theme.palette.grey[100] }}
                    >
                        <img
                            src={state.trData?.body?.data?.organizationLogo}
                            alt="Logo"
                            width={"100px"}
                            height={"100px"}
                        />
                    </BaasGrid>
                </>
            ) : null}

            {/* Loading info */}
            {loading ? (
                <BaasGrid
                    p="16px"
                    sx={{ background: theme.palette.grey[100] }}
                >
                    <div style={{ textAlign: "center", padding: "16px" }}>
                        <CircularProgress color="inherit" />
                        <BaasText textAlign="center" variant="h6">
                            Loading transaction data...
                        </BaasText>
                    </div>
                </BaasGrid>
            ) : null}

            {/* Transaction Info display */}
            {state.trData !== null ? (
                <>
                    <BaasRow item spacing={1}>
                        <BaasText variant="h6">
                            Transaction Information
                        </BaasText>
                    </BaasRow>
                    <BaasGrid
                        p="16px"
                        sx={{ background: theme.palette.grey[100] }}
                    >
                        <BaasRow container spacing={1}>
                            <BaasGrid item xs={12}>
                                <BaasInfoDisplay
                                    label={"PISP Organization Name:"}
                                    value={state.trData?.body?.organizationName}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                {console.log(state.trData)}
                                <BaasInfoDisplay
                                    label={"Amount:"}
                                    value={`R$ ${state.trData?.body?.data?.payment?.amount}`}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Currency:"}
                                    value={state.trData?.body?.data?.payment?.currency}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Date:"}
                                    value={state.trData?.body?.data?.payment?.date}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"PISP User Tax Id:"}
                                    value={
                                        state.trData?.body?.data?.loggedUser.document
                                            .identification
                                    }
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"PISP User Document Type:"}
                                    value={
                                        state.trData.body?.data?.loggedUser?.document.rel
                                    }
                                />
                            </BaasGrid>
                        </BaasRow>
                    </BaasGrid>
                </>
            ) : null}

            {/* Creditor Info Display */}
            {state.trData !== null ? (
                <>
                    <BaasRow item spacing={1}>
                        <BaasText variant="h6">Creditor Information</BaasText>
                    </BaasRow>
                    <BaasGrid
                        p="16px"
                        sx={{ background: theme.palette.grey[100] }}
                    >
                        <BaasRow container spacing={1}>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Creditor Name:"}
                                    value={state.trData?.body?.data?.creditor?.name}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Creditor CPF/CNPJ:"}
                                    value={state.trData?.body?.data?.creditor?.cpfCnpj}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Account Number:"}
                                    value={
                                        state.trData?.body?.data?.payment?.details
                                            .creditorAccount?.number
                                    }
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Account Type:"}
                                    value={
                                        state.trData?.body?.data?.payment?.details
                                            .creditorAccount?.accountType
                                    }
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Ispb:"}
                                    value={
                                        state.trData?.body?.data?.payment?.details
                                            .creditorAccount?.ispb
                                    }
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Issuer:"}
                                    value={
                                        state.trData.body?.data?.payment?.details
                                            ?.creditorAccount?.issuer
                                    }
                                />
                            </BaasGrid>
                        </BaasRow>
                        {/* Action Buttons */}
                        <BaasRow
                            container
                            sx={{ justifyContent: "space-between" }}
                            flexDirection="row"
                        >
                            <BaasGrid item p="16px 0px">
                                <BaasButton
                                    variant="outlined"
                                    backgroundColor="black"
                                    disabled={!state.trData ? true : false}
                                    onClick={() => approveTr(false)}
                                >
                                    <CancelIcon style={{ paddingRight: "8px" }} />
                                    Deny
                                </BaasButton>
                            </BaasGrid>
                            <BaasGrid item p="16px 0px">
                                <BaasButton
                                    variant="contained"
                                    backgroundColor="black"
                                    disabled={!state.trData ? true : false}
                                    onClick={() => approveTr(true)}
                                >
                                    <CheckIcon
                                        color="success"
                                        style={{ paddingRight: "8px" }}
                                    />
                                    Approve
                                </BaasButton>
                            </BaasGrid>
                        </BaasRow>
                    </BaasGrid>
                </>
            ) : null}
        </>
    );
}
