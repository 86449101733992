import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { ReactComponent as Logo } from "assets/btg-logo-md.svg";
import BaasButton from "components/atoms/Button/Button";
import BaasContainer from "components/atoms/Container/Container";
import BaasFlex from "components/atoms/Flex/Flex";
import {
  default as BaasGrid,
  default as BaasRow,
} from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasLoginTextField from "components/molecules/LoginTextField/LoginTextField";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { useAuth } from "providers/auth";
import { useOtp } from "providers/oneTimePassword";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import { recaptchaV3Config } from "constants/recaptcha";
import { recaptchaV2Config } from "constants/recaptcha";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import BaasRecaptcha from "components/molecules/BaasRecaptcha/BaasRecaptcha";
import LoginForgotMyPassword from "./LoginForgotMyPassword";
import LoginRegisterNewOtp from "./LoginRegisterNewOtp";

const AuthLogin = () => {
  const theme = useTheme();
  const history = useHistory();
  const { handleLogin } = useAuth();
  const { isOtpRequired } = useOtp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [recaptchaV2Props, setReCaptchaV2Props] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [reCaptcha, setReCaptcha] = useState("");
  const [validated, setValidated] = useState(false);
  const [forget, setForget] = useState(false);
  const [newOtp, setNewOtp] = useState(false);

  const verifyReCaptcha = (response) => {
    if (response !== null) {
      setValidated(true);
      setReCaptcha(response);
    } else {
      setValidated(false);
    }
  };

  const onSubmit = useCallback(
    (values, formik) => {
      handleLogin(
        {
          userId: values.email.toLowerCase().trim(),
          password: values.password.trim(),
          otpCode: values.otp || null,
          reCaptcha: recaptchaV2Props
            ? reCaptcha.trim()
            : values.recaptchaToken.trim(),
          reCaptchaVersion: recaptchaV2Props ? "V2" : "V3",
        },
        {
          onError: async (e) => {
            let errorText = await new Response(e.body).text();
            if (e.status === 400) {
              if (
                JSON.stringify(errorText).includes(
                  recaptchaV3Config.lowScoreV2IsRequiredError
                )
              ) {
                setReCaptchaV2Props({
                  siteKey: recaptchaV2Config.siteKey,
                  verifyReCaptcha: verifyReCaptcha,
                });
              }
              if (
                JSON.stringify(errorText).includes(
                  recaptchaV3Config.responseInvalidError
                )
              ) {
                setReCaptchaV2Props(undefined);
                formik.setValues({
                  email: "",
                  otp: "",
                  password: "",
                });
              }
            }
            toast.error(toastMessages.loginFail);
          },
          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    [handleLogin, reCaptcha, recaptchaV2Props]
  );

  const handleForgetPassword = () => {
    setForget(true)
  };

  const handleRegisterNewOtp = () => {
    setNewOtp(true)
  };

  // const handleRegisterNewClient = () => {
  //   history.push("/onboarding");
  // };

  const handleReCaptchaV3Verify = useCallback(
    async (values, formik) => {
      if (!executeRecaptcha) {
        toast.error("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha("login");

      onSubmit?.({ ...values, recaptchaToken: token }, formik);
    },
    [executeRecaptcha, onSubmit]
  );

  const needToValidateV2 = recaptchaV2Props && !validated;

  if (forget === true) {
    return (
      <LoginForgotMyPassword />
    )
  }

  if (newOtp === true) {
    return (
      <LoginRegisterNewOtp />
    )
  }

  return (
    <>
      <BaasContainer width="100%">
        <Formik
          initialValues={{}}
          onSubmit={recaptchaV2Props ? onSubmit : handleReCaptchaV3Verify}
        >
          {({ isSubmitting, values, ...props }) => (
            <Form>
              <BaasGrid container sx={{ marginTop: isMobile ? "0px" : "32px" }}>
                <BaasGrid xs={0} md={3} />
                <BaasGrid
                  xs={12}
                  md={6}
                  sx={{
                    backgroundColor: theme.palette.grey[300],
                    borderRadius: "4px",
                  }}
                  p="32px"
                >
                  <BaasFlex style={{ marginBottom: "16px" }}>
                    <Logo />
                  </BaasFlex>
                  <BaasRow>
                    <BaasText variant="h4" color="grey.700">
                      Sign in
                    </BaasText>
                  </BaasRow>
                  <BaasRow />
                  <BaasRow>
                    <BaasText variant="body2" color="grey.700">
                      Please fill the form below with your email and password
                      registered upon sign up.
                    </BaasText>
                  </BaasRow>
                  <BaasRow />
                  <BaasRow>
                    <BaasTextField name="email" fullWidth label="Email" />
                  </BaasRow>
                  <BaasRow>
                    <BaasLoginTextField
                      name="password"
                      fullWidth
                      type="password"
                      label="Password"
                    />
                  </BaasRow>

                  {isOtpRequired && (
                    <BaasRow>
                      <BaasTextField
                        name="otp"
                        fullWidth
                        type="text"
                        label="OTP"
                      />
                    </BaasRow>
                  )}

                  {recaptchaV2Props && (
                    <BaasGrid
                      display="flex"
                      justifyContent="center"
                      p="0px 0px 32px 0px "
                    >
                      <BaasRecaptcha
                        siteKey={recaptchaV2Props.siteKey}
                        verifyReCaptcha={(resp) =>
                          recaptchaV2Props.verifyReCaptcha(resp)
                        }
                      />
                    </BaasGrid>
                  )}

                  <BaasRow
                    container
                    sx={{ justifyContent: "space-between", margin: "24px 0px" }}
                  >
                    <BaasText
                      onClick={handleForgetPassword}
                      variant="subtitle2"
                      color="primary"
                      style={{ cursor: "pointer" }}
                    >
                      Forgot my password
                    </BaasText>
                    {isOtpRequired && (
                      <BaasText
                        onClick={handleRegisterNewOtp}
                        variant="subtitle2"
                        color="primary"
                        style={{ cursor: "pointer" }}
                      >
                        Register new OTP
                      </BaasText>
                    )}
                  </BaasRow>
                  <BaasRow />
                  <BaasRow
                    container
                    sx={{ justifyContent: "space-between" }}
                    flexDirection="row"
                  >
                    <a
                      variant="button"
                      href="https://developer.btgpactual.com/faq"
                    >
                      <BaasGrid p={0} item>
                        <BaasButton size="medium" variant="outlined">
                          Contact Us
                        </BaasButton>
                      </BaasGrid>
                    </a>
                    <BaasGrid p={0} item>
                      <BaasButton
                        type="submit"
                        size="medium"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={isSubmitting || needToValidateV2}
                      >
                        Connect
                      </BaasButton>
                    </BaasGrid>
                  </BaasRow>
                </BaasGrid>
              </BaasGrid>
            </Form>
          )}
        </Formik>
      </BaasContainer>
    </>
  );
};

const LoginPage = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaV3Config.recaptchaKey}>
      <AuthLogin />
    </GoogleReCaptchaProvider>
  );
};

export default LoginPage;
