import { baasApiCall } from "services/apiCall";

export const queryGetEnrollmentByPactualId = (pactualId) => {
  return baasApiCall("GET", `/bk/receiver/payment/jwr/enrollment/${pactualId}`)
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetEnrollments = (payload) => {
  return baasApiCall(
    "GET",
    `/bk/receiver/payment/jwr/enrollment/taxId/${payload.authorizationServerId}/${payload.taxId}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationEnrollment = (payload) => {
  return baasApiCall("POST", `/bk/receiver/payment/jwr/enrollment`, payload)
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationFidoRegistration = (payload) => {
  return baasApiCall(
    "POST",
    `/bk/receiver/payment/jwr/enrollment/${payload.pactualId}/fido-registration`,
    payload
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationCreateConsent = (payload) => {
  return baasApiCall(
    "POST",
    `/bk/receiver/payment/jwr/pix/${payload.enrollmentPactualId}`,
    payload
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationAuthoriseConsent = (payload) => {
  return baasApiCall(
    "POST",
    `/bk/receiver/payment/jwr/pix/authorise/${payload.consentPactualId}`,
    payload
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetPaymentStatus = (payload) => {
  return baasApiCall(
    "GET",
    `/bk/receiver/payment/pix/${payload?.params?.pactualId}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};
