import { Box } from "@mui/material";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import AccountTypeSelectedLabel from "components/molecules/AccountTypeSelectedLabel/AccountTypeSelectedLabel";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { Form, Formik } from "formik";
import { useQueryGetUserInfo } from "api/security-api";
import PixLimitValidatorCustomerList from "./PixLimitValidatorCustomerList";
import PixLimitValidatorCustomerDetails from "./PixLimitValidatorCustomerDetails";
import _ from "lodash";
import React, { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { queryGetPixMerchant } from "api/pixLimitValidator/pixLimitValidator";
import { queryPixMerchantByTaxId } from "api/pixLimitValidator/pixLimitValidator";

const PixLimitValidatorCustomer = () => {
  const [loading, setLoading] = useState(false);
  const [taxId, setTaxId] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const { data: clientData } = useQueryGetUserInfo();

  const onSearch = useCallback(
    (values) => {
      if (!clientData.clientId) {
        return toast.error("An error occurred, Client Id is unavailable.");
      }
      if (!values.taxId) {
        fetchData();
      } else {
        const queryVariables = {
          clientId: clientData.clientId,
          taxId: values.taxId,
        };
        handleSearch(queryVariables);
      }
    },
    [clientData?.clientId]
  );

  useEffect(() => {
    if (clientData) {
      fetchData();
    }
  }, [clientData]);

  const handleSearch = async (values) => {
    setLoading(true);

    const response = await queryPixMerchantByTaxId({ taxId: values.taxId });
    if (response.status === 404) {
      toast.error("Customer not found.");
      setData(initialData);
    } else if (response.status < 300) {
      setData(response.body);
    } else {
      toast.error("An error occurred while searching for the customer.");
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    const apiCallFunction = queryGetPixMerchant;

    try {
      const response = await apiCallFunction();
      if (response.status < 300) {
        setData(response.body);
        setInitialData(response.body);
      } else {
        if (response.status === 404) {
          toast.error("Customer not found.");
        }
        if ("errors" in response) {
          response.errors.map((error) => {
            return toast.error(error.message);
          });
        }
      }
    } catch (error) {
      toast.error("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };
  const onBack = () => {
    setSelectedItem(false);
  };

  return (
    <>
      <Box flexDirection="column" width="100%">
        <BaasPageHeader>Pix Limit Validator</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasGrid display="flex">
              <BaasText variant="h6">
                {selectedItem
                  ? "Customer Details"
                  : "Pix Limit Validator Customer"}
              </BaasText>
              <AccountTypeSelectedLabel />
            </BaasGrid>
          </BaasGrid>
          {selectedItem && (
            <PixLimitValidatorCustomerDetails
              selectedItem={selectedItem}
              onBack={onBack}
            />
          )}
          <Box style={{ visibility: selectedItem ? "hidden" : "visible" }}>
            <Formik initialValues={{ taxId: "" }} enableReinitialize>
              {() => (
                <Form>
                  <StackCard>
                    <BaasText>
                      Search for customers by passing the customer's TaxId.
                    </BaasText>
                    <BaasGrid container spacing={3}>
                      <BaasGrid item xs={12} sm={12}>
                        <BaasTextField
                          fullWidth
                          name="taxId"
                          label="Tax Id"
                          type="text"
                          required
                          value={taxId}
                          onChange={(e) => setTaxId(e.target.value)}
                        />
                      </BaasGrid>
                    </BaasGrid>
                    <BaasGrid item p="16px 0px">
                      <BaasButton
                        variant="contained"
                        backgroundColor="black"
                        loading={loading}
                        onClick={() => onSearch({ taxId })}
                      >
                        Search
                      </BaasButton>
                    </BaasGrid>
                  </StackCard>
                </Form>
              )}
            </Formik>
            <PixLimitValidatorCustomerList
              data={data || []}
              setSelectedItem={setSelectedItem}
              loading={loading}
            />
          </Box>
        </BaasContentGrid>
      </Box>
    </>
  );
};

export default PixLimitValidatorCustomer;
