import BaasGrid from "components/atoms/Grid/Grid";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { BackButton } from "components/molecules/BackButton/BackButton";
import { Box } from "@mui/system";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import { Typography } from "@mui/material";
import StackCard from "components/atoms/StackCard/StackCard";

import moment from "moment";

const PixLimitValidatorCustomerDetails = ({ selectedItem, onBack }) => {
  const formattedKey =
    selectedItem.data?.additionalData &&
    selectedItem.data.additionalData.length > 0
      ? selectedItem.data.additionalData.join(", ")
      : "N/A";
  const transactionValues = [
    {
      label: "Merchant Curstomer Id",
      value: selectedItem?.data.merchantCustomerId,
    },
    {
      label: "Name",
      value: selectedItem?.data.name,
    },
    {
      label: "TaxId",
      value: selectedItem?.data.taxId,
    },

    {
      label: "Status",
      value: selectedItem?.data.status,
    },
    {
      label: "Created At",
      value: selectedItem?.data?.createTimestamp
        ? moment(selectedItem.data.createTimestamp).format("YYYY/DD/MM")
        : "N/A",
    },
    {
      label: "Last Update",
      value: selectedItem?.data?.lastUpdateTimestamp
        ? moment(selectedItem.data.lastUpdateTimestamp).format("YYYY/DD/MM")
        : "N/A",
    },
    {
      label: "Additional Data",
      value: formattedKey,
    },
  ];

  return (
    <StackCard>
      <BaasContentGrid spacing={3}>
        <BackButton onBack={onBack} />
        <BaasGrid container mt={2} mb={2}>
          <BaasGrid item xs={12}>
            <Box mt={1}>
              <Typography style={{ fontWeight: "bold" }}>
                <b>Customer Information</b>
              </Typography>
            </Box>
          </BaasGrid>
          {transactionValues.map((row) => (
            <BaasGrid item xs={12} sm={6} md={4} key={row.label}>
              <Box mt={2}>
                <BaasInfoDisplay
                  fullWidth
                  label={row.label}
                  value={row?.value || "Not available"}
                />
              </Box>
            </BaasGrid>
          ))}
        </BaasGrid>
      </BaasContentGrid>
    </StackCard>
  );
};

export default PixLimitValidatorCustomerDetails;
