import InfoIcon from "@mui/icons-material/Info";
import { Box } from "@mui/material";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import moment from "moment";

export default function PixLimitValidatorCustomerList({
  data,
  loading,
  setSelectedItem,
}) {
  const onViewClick = (data) => {
    setSelectedItem({
      data,
    });
  };
  if (loading) {
    return (
      <>
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
      </>
    );
  }

  return (
    <BaasGrid style={{ marginTop: "16px" }}>
      <DinamicTable
        loading={loading}
        canDownloadCsv={data?.length > 0}
        data={data}
        disableRowHover={true}
        defaultSort={{
          key: "merchantCustomerId",
          orientation: "desc",
        }}
        tableSchema={[
          {
            header: "Merchant Customer Id",
            key: "merchantCustomerId",
            sort: true,
          },
          {
            header: "Client Id",
            key: "name",
            tooltip: true,
          },
          {
            header: "Tax Id",
            key: "taxId",
          },
          {
            header: "Create Timestamp",
            key: "createTimestamp",
            sort: true,
            formatValue: (data) =>
              data ? moment(data).format("YYYY/DD/MM") : "N/A",
          },
          {
            header: "Last Update Timestamp",
            key: "lastUpdateTimestamp",
            sort: true,
            formatValue: (data) =>
              data ? moment(data).format("YYYY/DD/MM") : "N/A",
          },
          {
            header: "Status",
            key: "status",
            sort: true,
          },
        ]}
        extraActions={[
          {
            onClick: (item) => onViewClick(item),
            icon: InfoIcon,
            tooltip: "More info",
          },
        ]}
      />
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-end"
        style={{ marginTop: "8px" }}
      ></Box>
    </BaasGrid>
  );
}
