import { minWidth, useTheme } from "@mui/system";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import BaasDialog from "components/organism/BaasDialog/BaasDialog";
import { Form, Formik } from "formik";
import React, { useMemo, useCallback, useState } from "react";
import * as yup from "yup";
import { useOtp } from "providers/oneTimePassword";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import {
  mutationProprietaryTefCreateTransaction,
  mutationProprietaryExternalTransaction,
} from "api/pixLimitValidator/pixLimitValidator";
import { toast } from "react-toastify";
import guidGenerator from "services/guidGenerator";
import { Box, Typography } from "@mui/material";
import { ReactComponent as LogoWhite } from "assets/btg-logo-sm-white.svg";
import { useAccount } from "providers/account";
import { currencyFormatter } from "helpers/currencyFormatter";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextFieldCopy from "components/molecules/TextFieldCopy/TextFieldCopy";
import { ReactComponent as ExternalLogo } from "../../../../../assets/psp_logo.svg";

export default function TedTransferDialog({
  accounts = [],
  open,
  setOpen: setOpenDialog,
  handleRefetchAccounts,
}) {
  const theme = useTheme();
  const { setOpen } = useOtp();
  const [submitingTransfer, setIsSubmitting] = useState(false);
  const [transferResponse, setTransferResponse] = useState(null);

  const initialValues = useMemo(() => {
    return {};
  }, []);

  const validationSchema = yup.object().shape({
    amount: yup.string().required(),
    direction: yup.string().required(),
  });

  const { showBalance } = useAccount();

  const getAccountById = (id) => {
    return accounts.find((account) => account.accountId === id);
  };

  const getBalanceValue = (account) => {
    if (!showBalance) {
      return "*****";
    }

    const balance = account?.availableBalance;
    if (balance === undefined || balance === null) {
      return "Balance N/A";
    }

    return `${currencyFormatter(balance / 100)} BRL`;
  };

  const mappedAccounts = (accounts || []).map((account, index) => {
    if (!account.accountId) {
      return {
        ...account,
        accountId: `${index}`,
      };
    }
    return account;
  });

  const internalAccounts = (accounts || []).filter(
    (account) => account.type !== "ExternalAccount"
  );

  const mutateCreateTransfer = useCallback(
    async (values, otp) => {
      const response = await mutationProprietaryTefCreateTransaction(
        values,
        otp
      );
      if (response.status < 300) {
        handleRefetchAccounts();
        toast.success("Transfer processed with success.");
        setOpenDialog(false);
      } else {
        setIsSubmitting(false);
        if ("errors" in response) {
          response.errors.map((error) => toast.error(error.message));
        } else toast.error("An error ocurred while transaction was processed");
      }
      setIsSubmitting(false);
    },
    [handleRefetchAccounts, setOpenDialog]
  );

  const mutateCreateExternalTransfer = useCallback(
    async (values, otp) => {
      const response = await mutationProprietaryExternalTransaction(
        values,
        otp
      );
      if (response.status < 300) {
        handleRefetchAccounts(response.body);
        toast.success("Transfer processed with success.");
        setTransferResponse(response.body)
        // setOpenDialog(false);
      } else {
        if ("errors" in response) {
          response.errors.map((error) => {
            return toast.error(error.message);
          });
        } else {
          toast.error("An error ocurred while transaction was processed");
        }
      }
      setIsSubmitting(false);
    },
    [handleRefetchAccounts, setOpenDialog]
  );

  const getAccountType = (accountType) => {
    if (accountType === "CC") {
      return "CACC";
    }
    if (accountType === "CP") {
      return "SVGS";
    }
    if (accountType === "PG") {
      return "TRAN";
    }
    return "CACC";
  };

  const onSubmit = useCallback(
    (values, props) => {
      setIsSubmitting(true);

      const accountTo = mappedAccounts.find(
        (account) => account.accountId === values.to
      );

      if (accountTo.type === "ExternalAccount") {
        const payload = {
          accountIdFrom: values.from,
          creditParty: {
            name: accountTo.name,
            bank: accountTo.bankIspb,
            branch: accountTo.branch,
            account: accountTo.account,
            taxId: accountTo.taxId,
            accountType: getAccountType(accountTo.accountType),
          },
          amount: values.amount,
          remittanceInformation: "Pix Limit Validator External Transfer",
          clientRequestId: guidGenerator(),
        };
        mutateCreateExternalTransfer(payload, values.otp);
      } else {
        const payload = {
          amount: values.amount * 100,
          accountFrom: values.from,
          accountTo: values.to,
          clientRequestId: guidGenerator().replaceAll("-", ""),
        };
        mutateCreateTransfer(payload, values.otp);
      }
    },
    [mappedAccounts, mutateCreateExternalTransfer, mutateCreateTransfer]
  );

  const accountTypeLabel = {
    TransactionalAccount: "Transactional",
    ProprietaryAccount: "Proprietary",
    PSP: "External PSP Account",
    InvestmentTransactional: "Investment",
    MigrationAccount: "Migration"
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, isSubmitting, setFieldValue, ...props }) => (
          <Form>
            <BaasOtpModal
              onConfirm={() => onSubmit(values, props)}
              {...props}
            />
            <BaasDialog
              open={open}
              setOpen={setOpenDialog}
              title={transferResponse ? "Request successful!" : "Transfer"}
              content={
                <>
                  {transferResponse ? (
                    <BaasGrid
                      sx={{
                        background: theme.palette.grey[100],
                        minWidth: "470px",
                        marginLeft: "-6px"
                      }}
                    >
                      <BaasRow style={{ width: "100%" }}>
                        <Typography marginLeft="7px" variant="body2" color="text.secondary" marginBottom="16px">
                          Use the End to End Id to search transaction information.
                        </Typography>
                        <Typography marginLeft="7px" marginTop={4} variant="body2" color="text.secondary" marginBottom="16px">
                          From: <span style={{ color: "black" }}>{transferResponse?.body?.debitParty.bank || "N/A"} {" | "}
                            {transferResponse?.body?.debitParty.branch || "N/A"}{" | "}
                            {transferResponse?.body?.debitParty.account || "N/A"}</span>
                        </Typography>
                        <Typography marginLeft="7px" variant="body2" color="text.secondary" marginBottom="16px">
                          To: <span style={{ color: "black" }}>{transferResponse?.body?.creditParty.bank || "N/A"} {" | "}
                            {transferResponse?.body?.creditParty.branch || "N/A"}{" | "}
                            {transferResponse?.body?.creditParty.account || "N/A"}</span>
                        </Typography>
                        <BaasTextFieldCopy
                          value={transferResponse.body?.endToEndId}
                          label={"End to End Id"}
                        />
                      </BaasRow>
                    </BaasGrid>
                  ) : (
                    <BaasGrid
                      sx={{
                        background: theme.palette.grey[100],
                        minWidth: "470px",
                      }}
                    >
                      <BaasRow style={{ width: "100%" }}>
                        <BaasGrid item sm={12} mt={1}>
                          <BaasTextFieldCurrency
                            fullWidth
                            required
                            name="amount"
                            label="Amount"
                          />
                        </BaasGrid>
                        <BaasGrid
                          item
                          sm={12}
                          mb={1}
                          style={{ paddingTop: "8px" }}
                        >
                          <BaasTextField
                            fullWidth
                            name="from"
                            label="From"
                            required
                            onChange={(e) => {
                              const direction = e.target.value;
                              setFieldValue("from", direction);
                              setFieldValue("to", "");
                            }}
                            select
                          >
                            {internalAccounts.map((account) => {
                              return (
                                <BaasMenuItem value={account.accountId}>
                                  <Box
                                    mb={2}
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                  >
                                    <LogoWhite />
                                    <Box ml={2}>
                                      <Typography fontWeight="bold">
                                        {accountTypeLabel[account.type]}
                                      </Typography>
                                      <Typography>
                                        R$
                                        {getBalanceValue(
                                          getAccountById(account.accountId)
                                        )}
                                      </Typography>{" "}
                                      <Typography color="textSecondary">
                                        {account.bankCode} | {account.branch} | {" "}
                                        {account.account}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </BaasMenuItem>
                              );
                            })}
                          </BaasTextField>
                        </BaasGrid>{" "}
                        <BaasGrid
                          item
                          sm={12}
                          mb={1}
                          style={{ paddingTop: "8px" }}
                        >
                          <BaasTextField
                            fullWidth
                            name="to"
                            label="To"
                            key={guidGenerator()}
                            required
                            select
                            value={values.to}
                          >
                            {(mappedAccounts || []).map((account) => {
                              return (
                                <BaasMenuItem
                                  value={account.accountId}
                                  disabled={values.from === account.accountId}
                                >
                                  <Box
                                    mb={2}
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                  >
                                    {account.type === "ExternalAccount" ? (
                                      <ExternalLogo />
                                    ) : (
                                      <LogoWhite />
                                    )}

                                    <Box ml={2}>
                                      <Typography fontWeight="bold">
                                        {account.type === "ExternalAccount" ? account.bankName || "External" : accountTypeLabel[account.type]}
                                      </Typography>
                                      <Typography>
                                        {account.type === "ExternalAccount" ? (
                                          <></>
                                        ) : (
                                          <>
                                            R$
                                            {getBalanceValue(
                                              getAccountById(account.accountId)
                                            )}
                                          </>
                                        )}
                                      </Typography>
                                      <Typography color="textSecondary">
                                        {account.bankCode} | {account.branch} | {" "}
                                        {account.account}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </BaasMenuItem>
                              );
                            })}
                          </BaasTextField>
                        </BaasGrid>
                      </BaasRow>
                    </BaasGrid>)}
                </>
              }
              actions={
                <>
                  <BaasRow
                    p="8px 0px 8px 0px"
                    container
                    sx={{ justifyContent: "space-between" }}
                    flexDirection="row"
                  >
                    <BaasGrid
                      pr={1}
                      pl={1}
                      item
                      display="flex"
                      justifyContent="flex-end"
                      width="100%"
                    >
                      {transferResponse ? (
                        <Box width="100%" display="flex" justifyContent="space-between">
                          <BaasButton
                            onClick={() => {
                              setFieldValue("from", "");
                              setFieldValue("to", "");
                              setFieldValue("amount", "");
                              setTransferResponse(null);

                            }}

                            style={{ minWidth: "170px" }}
                            size="medium"
                            variant="outlined"

                          >
                            Make a new transfer
                          </BaasButton>
                          <BaasButton
                            onClick={() => {
                              setTransferResponse(null);
                              setOpenDialog(false)
                            }}
                            style={{ minWidth: "170px" }}

                            size="medium"
                            variant="contained"

                          >
                            Close
                          </BaasButton>
                        </Box>
                      ) : (
                        <BaasButton
                          onClick={() => setOpen(true)}
                          size="medium"
                          variant="contained"
                          disabled={
                            !values.amount ||
                            !values.from ||
                            !values.to ||
                            submitingTransfer
                          }
                          loading={false}
                        >
                          {submitingTransfer ? "Transfering" : "Transfer"}
                        </BaasButton>)}
                    </BaasGrid>
                  </BaasRow>
                </>
              }
              onClickButton={() => setOpenDialog(false)}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
