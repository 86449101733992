import queryToString from "helpers/queryToString";
import { baasApiCallObk } from "services/apiCallObk";
import error from "../jsonServer/errors.json";
import loggedUser from "../jsonServer/loggedUser.json";

export const queryGetSearchBank = () => {
  // return organization;
  return baasApiCallObk("GET", `/mock/pisp/payment/authorization-server`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPaymentPix = (payload, queryParams) => {
  return baasApiCallObk(
    "POST",
    `/mock/pisp/payment${queryToString(queryParams)}`,
    payload
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostLoginAnotherBank = (payload) => {
  if (payload.email === "testmail@email.com") {
    return loggedUser;
  } else {
    return error;
  }
};

export const mutationPostPaymentAccount = (payload, pactualId, queryParams) => {
  return baasApiCallObk(
    "POST",
    `/mock/pisp/payment/${pactualId}${queryToString(queryParams)}`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostAuthorizePixKey = (
  pactualId,
  payload,
  queryParams
) => {
  return baasApiCallObk(
    "POST",
    `/mock/tpp/consent/authorize/${pactualId}${queryToString(queryParams)}`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostRejectPixKey = (pactualId, queryParams) => {
  return baasApiCallObk(
    "POST",
    `/mock/tpp/consent/reject/${pactualId}${queryToString(queryParams)}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostConsentValidate = (pactualId, payload, queryParams) => {
  return baasApiCallObk(
    "POST",
    `/mock/tpp/consent/validate/${pactualId}${queryToString(queryParams)}`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetObkTransaction = (pactualId, queryParams) => {
  return baasApiCallObk(
    "GET",
    `/mock/pisp/payment/${pactualId}${queryToString(queryParams)}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};
