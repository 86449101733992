import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  queryGetProprietaryTefAccounts,
  queryGetStatementPagination,
  queryGetStatement,
} from "api/pixLimitValidator/pixLimitValidator";
import { ReactComponent as LogoWhite } from "assets/btg-logo-sm-white.svg";
import BaasButton from "components/atoms/Button/Button";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import AccountTypeSelectedLabel from "components/molecules/AccountTypeSelectedLabel/AccountTypeSelectedLabel";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { Form, Formik } from "formik";
import { useCustomQuery } from "helpers/useCustomQuery";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import guidGenerator from "services/guidGenerator";
import PixLimitValidatorStatementsList from "./PixLimitValidatorStatementsList";

const PixLimitValidatorStatement = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState();
  const [paginatedData, setPaginatedData] = useState([]);
  const [loadingPaginated, setLoadingPaginated] = useState(false);

  const handleSearch = useCallback(async (values) => {
    const response = await queryGetStatement(values);
    if (response.status < 300) {
      console.log(response);
      setPaginatedData(response.body.items || []);
      setPagination({
        hasMoreItems: response.body?.hasMoreItems,
        scrollId: response.body?.scrollId,
        totalFound: response.body?.totalFound || 0,
      });
      setLoading(false);
    } else {

      if (response.status === 404) {
        setPaginatedData([]);
        setPagination({
          hasMoreItems: false,
          scrollId: null,
          totalFound: 0,
        });
        setLoading(false);
        return toast.warning("No results Found");
      }
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
      setPaginatedData([]);
      setPagination({
        hasMoreItems: false,
        scrollId: null,
        totalFound: 0,
      });
      setLoading(false);
    }
  }, []);

  const handleLoadMore = async () => {
    setLoadingPaginated(true);
    const response = await queryGetStatementPagination({
      scrollId: pagination.scrollId,
    });
    if (response.status < 300) {
      if (response.body?.items?.length > 0) {
        setPaginatedData([...paginatedData, ...response.body?.items]);
      }
      setPagination({
        hasMoreItems: response.body?.hasMoreItems,
        scrollId: response.body?.scrollId,
        totalFound: response.body?.totalFound || 0,
      });
      setLoadingPaginated(false);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
        setLoadingPaginated(false);
      }
    }
  };

  const {
    fetch: loadAccountsData,
    loading: loadingAccounts,
    data: accountsData,
  } = useCustomQuery(queryGetProprietaryTefAccounts);

  useEffect(() => {
    loadAccountsData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const internalAccounts = (accountsData || []).filter(
    (account) => account.type !== "ExternalAccount"
  );

  const accountTypeLabel = {
    TransactionalAccount: "Transactional",
    ProprietaryAccount: "Proprietary",
    PSP: "External PSP Account",
    InvestmentTransactional: "Investment",
    MigrationAccount: "Migration"
  };

  const mappedAccounts = (accountsData || []).map((account, index) => {
    if (!account.accountId) {
      return {
        ...account,
        accountId: `${index}`,
      };
    }
    return account;
  });

  // https://settle-movement-api.uat.baas.container.btgpactual.net/swagger/index.html
  const onSearch = useCallback(
    (values) => {
      setLoading(true);
      const date = `${moment(values.date).format("YYYY-MM-DD")}`;
      let params = {
        date: date,
      };

      if (values.to) {
        const accTo = mappedAccounts.find((acc) => acc.accountId === values.to);
        params = {
          ...params,
          bankTo: accTo.bankCode,
          branchTo: accTo.branch,
          accountTo: accTo.account,
        };
      }

      if (values.from) {
        const accFrom = mappedAccounts.find(
          (acc) => acc.accountId === values.from
        );
        params = {
          ...params,
          bankFrom: accFrom.bankCode,
          branchFrom: accFrom.branch,
          accountFrom: accFrom.account,
        };
      }

      handleSearch(params);
    },
    [handleSearch, mappedAccounts]
  );

  const styles = (theme) => ({
    textField: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 0,
      marginTop: 0,
      height: "900px",
      fontWeight: 500,
    },
    input: {
      color: "white",
    },
  });

  return (
    <BaasFlex flexDirection="column" width="100%">
      <BaasPageHeader>Pix Limit Validator</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid p="16px 0px" item>
          <BaasGrid display="flex">
            <BaasText variant="h6">Statement</BaasText>
            <AccountTypeSelectedLabel />
          </BaasGrid>
        </BaasGrid>
        <Formik
          initialValues={{
            from: "",
            to: "",
            date: moment(),
          }}
          onSubmit={onSearch}
        >
          {({ values, setFieldValue, ...props }) => {
            return (
              <Form>
                <StackCard>
                  <BaasGrid item sm={12} mb={4}>
                    <BaasText>Statement Search.</BaasText>
                  </BaasGrid>
                  <BaasGrid container spacing={3}>
                    <>
                      {accountsData?.length === 0 && !loadingAccounts ? (
                        <>
                          <BaasGrid
                            item
                            md={12}
                            sm={12}
                            style={{ paddingTop: "8px" }}
                          >
                            <Typography color="textSecondary">
                              No accounts found...
                            </Typography>
                          </BaasGrid>
                        </>
                      ) : (
                        <>
                          <BaasGrid
                            item
                            sm={12}
                            md={6}
                            mb={1}
                            style={{ paddingTop: "8px" }}
                          >
                            <BaasTextField
                              fullWidth
                              name="from"
                              label={
                                loadingAccounts ? "Loading" : "Account From"
                              }
                              disabled={loadingAccounts}
                              InputProps={{
                                className: styles.input,
                              }}
                              onChange={(e) => {
                                const direction = e.target.value;
                                setFieldValue("from", direction);
                                setFieldValue("to", "");
                              }}
                              select
                            >
                              <BaasMenuItem value={""}>
                                <Typography>Select</Typography>
                              </BaasMenuItem>
                              {internalAccounts.map((account) => {
                                return (
                                  <BaasMenuItem value={account.accountId}>
                                    <Box display="flex">
                                      <Typography color="textSecondary">
                                        {account.bankCode}{" | "}
                                        {account.branch} {" | "}
                                        {account.account}
                                      </Typography>
                                      <Typography fontWeight="bold" marginLeft="6px">
                                        {accountTypeLabel[account.type]}
                                      </Typography>
                                    </Box>

                                  </BaasMenuItem>
                                );
                              })}
                            </BaasTextField>
                          </BaasGrid>
                          <BaasGrid
                            item
                            sm={12}
                            md={6}
                            mb={1}
                            style={{ paddingTop: "8px" }}
                          >
                            <BaasTextField
                              fullWidth
                              name="to"
                              label={loadingAccounts ? "Loading" : "Account To"}
                              disabled={loadingAccounts}
                              key={guidGenerator()}
                              select
                              value={values.to}
                            >
                              <BaasMenuItem value={""}>
                                <Typography>Select</Typography>
                              </BaasMenuItem>
                              {(mappedAccounts || []).map((account) => {
                                return (
                                  <BaasMenuItem
                                    value={account.accountId}
                                    disabled={values.from === account.accountId}
                                  >
                                    <Box display="flex">

                                      <Typography color="textSecondary">
                                        {account.bankCode}  {" | "}{account.branch} {" | "}
                                        {account.account}
                                      </Typography>
                                      <Typography fontWeight="bold" marginLeft="6px">
                                        {account.type ===
                                          "ExternalAccount" ? (
                                          <>{account.bankName || "External"}</>
                                        ) : (
                                          <>{account.bankName || accountTypeLabel[account.type]}</>
                                        )}
                                      </Typography>
                                    </Box>
                                  </BaasMenuItem>
                                );
                              })}
                            </BaasTextField>
                          </BaasGrid>
                        </>
                      )}
                      <BaasGrid item xs={12} sm={6}>
                        <BaasDatePicker name="date" label="Date" fullWidth />
                      </BaasGrid>
                    </>
                  </BaasGrid>
                  <BaasGrid item p="16px 0px">
                    <BaasButton
                      variant="contained"
                      backgroundColor="black"
                      loading={false}
                      type="submit"
                    >
                      Search
                    </BaasButton>
                  </BaasGrid>
                </StackCard>
              </Form>
            );
          }}
        </Formik>
        <PixLimitValidatorStatementsList
          data={paginatedData}
          loading={loading}
          loadingPaginated={loadingPaginated}
          loadMore={handleLoadMore}
          pagination={pagination}
        />
      </BaasContentGrid>
    </BaasFlex>
  );
};
export default PixLimitValidatorStatement;
